import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header/header"
import "./layout.css"
import Footer from "./footer/footer";

const Layout = ({ children, isTop }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div id={"top"}>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} isTop={isTop || false} />
      <div>
        <main>{children}</main>

        <Footer />
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isTop: PropTypes.bool,
}

export default Layout
