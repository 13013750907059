/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import facebookIcon from "../../images/icon-facebook.svg"
import instagramIcon from "../../images/icon-instagram.svg"
import twitterIcon from "../../images/icon-twitter.svg"
import {graphql, useStaticQuery} from "gatsby";

const Sns = () => {
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          instagram
          twitter
        }
      }
    }
  `)

    return (
        <div sx={{
            display: "flex",
            justifyContent: "flex-end",
            "a": {
                width: 35,
                height: 35,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#ccc",
                borderRadius: "100%",
                margin: "0 5px"
            }
        }}>
            <a href={data.site.siteMetadata.twitter} target={"_blank"} rel={"noopener noreferrer"}>
                <img src={twitterIcon} style={{width: 23}} />
            </a>
            <a href={data.site.siteMetadata.instagram} target={"_blank"} rel={"noopener noreferrer"}>
                <img src={instagramIcon} style={{width: 20}} />
            </a>
        </div>
    )
}

export default Sns
