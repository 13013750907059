/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import {Link} from "gatsby";
import towaLogo from "../../images/towa-logo.png";

const Logo = ({siteTitle, isSticky}) => {
    return (
        <h1 sx={{
            marginTop: isSticky? 25 : 40,
            marginBottom: isSticky? 25 : 50,
            textAlign: "center",
            transition: "all 200ms ease-in",
            "@media (max-width: 901px)": {
                marginTop: isSticky? 10 : 25,
                marginBottom: isSticky? 10 : 20,
            },
            "@media (max-width: 1085px)": {
                textAlign: "left",
                paddingLeft: 30
            }
        }}>
            <Link to="/" sx={{
                display: "inline-block",
                "@media (max-width: 901px)": {
                    width: 123
                },
                "img": {
                    transition: "all 150ms ease-in",
                    width: isSticky? 123 : 223
                }
            }}>
                <img src={towaLogo} alt={siteTitle} style={{verticalAlign: "bottom"}} />
            </Link>
        </h1>
    )
}

Logo.Logo = {
    siteTitle: PropTypes.string.isRequired,
    isSticky: PropTypes.bool,
}

export default Logo
