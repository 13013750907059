/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Sns from "./sns";
import Links from "./links";
import towaLogoColorful from "../../images/towa-logo-colorful.png";

const Footer = () => {
    return (
        <div style={{
            background: "#fff"
        }}>
            <div sx={{
                position: "relative",
                margin: "0 auto",
                zIndex: 1,
                alignSelf: "center",
                maxWidth: "960px",
                padding: `0 1.0875rem 0`,
            }}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                }}>
                    <div sx={{
                        maxWidth: 387,
                        "@media (max-width: 701px)": {
                            maxWidth: 240,
                        },
                    }}>
                        <img src={towaLogoColorful} alt={"TOWA"} style={{width: "100%"}} />
                    </div>
                    <div sx={{
                        marginLeft: "auto", display: "flex", alignItems: "flex-end",
                        "@media (max-width: 701px)": {
                            flexDirection: "column",
                        },
                    }}>
                        <Sns />
                        <div sx={{
                            marginLeft: 35,
                            whiteSpace: "nowrap",
                            "@media (max-width: 701px)": {
                                marginTop: 15,
                            },
                        }}>
                            <Links />
                        </div>
                    </div>
                </div>

                <p style={{
                    textAlign: "center",
                    fontSize: 12,
                    marginTop: 10,
                    paddingTop: 21,
                    paddingBottom: 20,
                    borderTop: "1px solid #C6C6C6",
                    color: "#42210B",
                }}>
                    © {new Date().getFullYear()}, TOWA,inc., All Rights Reserved.
                </p>
            </div>
        </div>
    )
}

export default Footer
