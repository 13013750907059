/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import facebookIcon from "../../images/icon-facebook.svg"
import instagramIcon from "../../images/icon-instagram.svg"
import twitterIcon from "../../images/icon-twitter.svg"
import {Link} from "gatsby";

const Links = () => {
    return (
        <div sx={{
            display: "flex",
            justifyContent: "flex-end",
            fontSize: 12,
            color: "#42210B",
            "a": {
                textDecoration: "none",
                fontSize: 12,
                color: "#42210B",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 5px"
            }
        }}>
            <a href={"/contact"}>
                Contact
            </a>
            |
            <a href={"/privacy"}>
                Privacy policy
            </a>
        </div>
    )
}

export default Links
